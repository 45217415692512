// Override CSS

$light: #EFF2FC;
$primary: rgba(15, 94, 247, 1);

*:focus {
    outline: none !important;
    box-shadow: none !important;
}

.bg-light,
.aui-navgroup-vertical .aui-nav .aui-nav-selected,
.aui-sidebar .aui-navgroup-vertical .aui-nav .aui-nav-selected>.aui-nav-item {
    background-color: $light !important;
}

.aui-sidebar[aria-expanded=false] .aui-sidebar-group-tier-one .aui-nav>li {
    padding: 12px;
}

.aui-sidebar[aria-expanded=false] .aui-sidebar-group-tier-one .aui-nav>li.aui-nav-selected>.aui-nav-item>.aui-icon{
    color: $primary !important;
}

.aui-page-sidebar #content+#footer, .aui-page-sidebar #content .aui-sidebar+main, .aui-page-sidebar #content .aui-sidebar+section, .aui-page-sidebar #content .aui-sidebar~[class^=aui-] {
    padding-left: 56px !important;
}


.app-header {
    height: 60px;
}

.print-bill {
    overflow: scroll;
    margin-top: 10px;
    width: 100%;
    background-color: red($color: #777777);
    padding: 10px;
    // border: 2px dotted #b2b2b2;
}