body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@media print {

  @page {
    size: auto;
    margin: 10mm 10mm 10mm 10mm;
  }
  body {
    background-color: #fff;
    margin: 0;
    padding: 0;
  }

  table {
    background-color: #fff;
    page-break-inside: auto;
  }
  tr {
    page-break-inside: avoid;
    page-break-after: auto;
  }
  thead {
    display: table-header-group;
  }
  tfoot {
    display: table-footer-group;
  }


  .sales-details.print-data {
  }
}

.fade:not(.show) {
  opacity: 1;
}